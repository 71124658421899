<template>
  <div class="userIndex-box">
    <div class="content">
      <div class="lever-one"></div>
      <div class="lever-two">
        <div class="sub-title">设置</div>
        <user bgColor="#fff" class="pl30"></user>
      </div>
      <div class="setBox">
        <div class="img-box" @click="fileClick">
          <img :src="userInfo.avatar" alt="" />
          <p>管理员头像</p>
          <input
            @change="fileChange($event)"
            type="file"
            id="upload_file"
            accept="image/*"
            multiple
            style="display: none"
          />
        </div>
        <div class="form-box">
          <div class="item one">
            <span class="sub start">昵称：</span>
            <div class="inp">
              <input type="text" v-model="userInfo.nickName" />
            </div>
          </div>
          <div class="item two">
            <span class="sub start">性别：</span>
            <div class="inp">
              <el-select v-model="userInfo.sex" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item three">
            <span class="sub">手机号：</span>
            <div class="inp cursor">
              <p>{{ userInfo.mobile }}</p>
              <span class="" @click="changeHandle">更换手机号</span>
            </div>
          </div>
          <div class="item four">
            <span class="sub">登录密码：</span>
            <div class="inp cursor" @click="changeHandle('password')">
              <span>修改登录密码</span>
            </div>
          </div>
        </div>
        <div class="txt-des">
          <div class="des">
            <!-- 所属机构：{{ userInfo.store ? userInfo.store.storeName : '暂无' }} -->
          </div>
          <div class="des">注册日期：{{ userInfo.createDate }}</div>
        </div>
        <div class="edit-btn cursor" @click="saveHandle">保存修改</div>
      </div>
      <div class="organiza-box" ref="container" v-if="isManager">
        <div class="title">
          <span class="sub-title">我开设的子账号</span>
          <div class="org-count">
            共可开设 <span>{{ limitQty }}</span
            >个 <i>|</i> 已开设<span>{{ comList.length }}</span
            >个
          </div>
        </div>
        <div class="list-box" :class="isSmall ? 'cur' : ''">
          <div class="list-title">
            <span class="name w120">序号</span>
            <span class="name w170">类型</span>
            <span class="name w280">子账户名称</span>
            <span class="name w210">手机号码</span>
            <span class="name w330">添加时间</span>
            <span class="name w120">状态</span>
            <span class="name w280">操作</span>
          </div>
          <div class="add-li cursor" @click="addChildren">
            <img src="@/assets/img/add-two.png" alt="" />
            <span>添加子账号</span>
          </div>
          <ul>
            <li v-for="(item, index) in comList" :key="item.mobile">
              <span class="name w120">{{ index + 1 }}</span>
              <span class="name w170">{{ item.departmentName }}</span>
              <span class="name w280">{{ item.nickName || '未设置' }}</span>
              <span class="name w210">{{ item.mobile }}</span>
              <span class="name w330">{{ item.createTime }}</span>
              <span
                class="name w120"
                :class="item.state === 'FORBID' ? 'stop' : ''"
                >{{ item.state === 'FORBID' ? '停用' : '正常' }}</span
              >
              <div
                class="name w280 operate cursor"
                v-if="item.state === 'FORBID'"
              >
                <i
                  class="refresh fcenter"
                  @click="operateHandle('refresh', item.id, index)"
                  >恢复</i
                >
                <i
                  class="del fcenter"
                  @click="operateHandle('delete', item.id, index)"
                  >删除</i
                >
              </div>
              <div class="name w280 operate cursor" v-else>
                <i
                  class="fcenter"
                  @click="operateHandle('stop', item.id, index)"
                  >停用</i
                >
                <i
                  class="del fcenter"
                  @click="operateHandle('delete', item.id, index)"
                  >删除</i
                >
              </div>
            </li>
          </ul>
          <Loading :loaded="loadend" :loading="loading"></Loading>
        </div>
      </div>
    </div>

    <div class="add-child">
      <el-dialog
        title="开设子账号"
        :visible.sync="dialogFormVisible"
        width="540px"
        :before-close="resetM"
      >
        <div class="form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="" prop="departmentId">
              <div class="item">
                <el-select
                  v-model="form.departmentId"
                  placeholder="选择子账号类别"
                >
                  <el-option
                    v-for="item in departList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <div class="item">
                <el-input
                  v-model="form.mobile"
                  placeholder="填写管理员手机号"
                ></el-input>
                <span
                  class="code cursor"
                  :class="isCountDown ? 'no-click-gray' : ''"
                  @click="sendCodeHandle"
                  >{{ codeTip }}</span
                >
              </div>
            </el-form-item>
            <el-form-item label="" prop="code">
              <div class="item">
                <el-input
                  v-model="form.code"
                  placeholder="填写验证码"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="" prop="password">
              <div class="item">
                <el-input
                  v-model="form.password"
                  placeholder="填写密码"
                  :type="isPassword ? 'password' : 'text'"
                ></el-input>
                <span
                  class="icon "
                  :class="!isPassword ? 'cur' : ''"
                  @click="isPassword = !isPassword"
                ></span>
              </div>
            </el-form-item>
            <el-form-item label="" prop="repassword">
              <div class="item">
                <el-input
                  v-model="form.repassword"
                  placeholder="重复密码"
                  :type="isPassword ? 'password' : 'text'"
                ></el-input>
                <span
                  class="icon "
                  :class="!isPassword ? 'cur' : ''"
                  @click="isPassword = !isPassword"
                ></span>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="btn fcenter" @click="submitForm('ruleForm')">
                确认开通
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 修改手机号 -->
    <ChangeMobile
      :isShow="isShow"
      :mobile="baseMobile"
      @updatePage="getInit"
    ></ChangeMobile>
  </div>
</template>

<script>
import {
  userIndex,
  storeEmployeeList,
  departmentList,
  sendCodeApi,
  addOrgApi,
  editOrgChildApi,
  deleteOrgChildApi,
  getCodeApi,
  changePersonInfoApi
} from '@/api/user'
import User from '@/components/user.vue'
import Loading from '@/components/Loading.vue'
import ChangeMobile from './components/ChangeMobile.vue'
import { setItem, getItem } from '@/assets/js/storage.js'
import { Scroll } from '@/utils/loading'
let userInfo = getItem('user')
let baseHttp = process.env.VUE_APP_BASE_URL
export default {
  name: 'course',
  components: {
    User,
    Loading,
    ChangeMobile
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else {
        if (!reg_tel.test(value)) {
          callback(new Error('请输入正确手机号码'))
        }
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length < 6) {
          callback(new Error('密码不能少于6位数'))
        }
        if (this.form.repassword !== '') {
          this.$refs.form.validateField('repassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isManager: 1,
      isSmall: false,
      codeTip: '获取验证码',
      isCountDown: false,
      baseMobile: '',
      imgCode: '',
      isUser: false,
      isPassword: true,
      isShowPassword: 1,
      isShow: 1,
      inpuyType: 'text',
      departList: [],
      userInfo: {},
      currentCount: 0,
      limitQty: 0,
      comList: [],
      loading: false,
      loadend: false,
      where: {
        page: 1,
        pagesize: 10
      },
      allTotal: '',
      sex: '',
      options: [
        {
          value: 'MAN',
          label: '男'
        },
        {
          value: 'WOMEN',
          label: '女'
        }
      ],
      value: '',
      dialogTableVisible: false,
      dialogFormVisible: false,

      form: {},
      formLabelWidth: '120px',
      rules: {
        departmentId: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        mobile: [
          {
            required: true,
            validator: validateMobile,
            message: '请填写正确手机号码',
            trigger: 'change'
          }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        repassword: [{ validator: validatePass2, trigger: 'blur' }],
        code: [{ required: true, message: '请填写验证码', trigger: 'change' }]
      }
    }
  },

  mounted() {
    this.isSmall = document.body.clientWidth < 1510 ? true : false
    this.getInit()
    // 下拉加载
    this.$nextTick(() => {
      this.$scroll(this.$refs.container, () => {
        console.log(1)
        if (this.allTotal > this.comList.length) {
          !this.loading && this.getList()
        }
      })
      Scroll.listenTouchDirection()
    })

    // this.$bus.$on('changePassM', this.changeHandle)
  },
  methods: {
    // 初始化
    getInit(val) {
      userIndex({}).then(res => {
        if (res.code === 0) {
          this.userInfo = res.data
          this.isManager = res.data.isManager
          this.baseMobile = res.data.mobile
          // 是否要加载下面的子账户管理
          if (res.data.isManager && !val) {
            this.getList()
            this.getdepList()
          }
        }
      })
    },
    resetM() {
      this.$refs['form'].resetFields()
      this.dialogFormVisible = false
    },
    addChildren() {
      if (this.comList.length >= this.limitQty) {
        this.$message.error('子账号数量已达到可开设数量')
        return
      }
      this.dialogFormVisible = true
    },
    // 子账户列表
    getList(topVal) {
      if (!topVal) {
        if (this.loading) return
        if (this.loadend) return
      }
      this.loading = true
      storeEmployeeList(this.where).then(res => {
        if (res.code === 0) {
          this.allTotal = res.data.count
          if (topVal) {
            this.comList = res.data.list
          } else {
            this.comList.push.apply(this.comList, res.data.list)
          }
          this.loading = false
          this.loadend = res.data.list.length < this.where.pagesize
          this.where.page = this.where.page + 1
          this.currentCount = res.data.count
          this.limitQty = res.data.limitQty
        }
      })
    },
    // 获取验证码
    sendMoblieCodeHandle() {
      getCodeApi({
        t: 'checkMobile',
        smsCaptchaCode: this.changeMobileForm.codeImg
      }).then(res => {
        if (res.code === 0) {
          console.log('获取成功')
        }
      })
    },
    // 点击显示更换手机 ,密码
    changeHandle(type) {
      if (type === 'password') {
        // this.isShowPassword += 1
        this.$bus.$emit('changePassM', 'password')
      } else {
        this.isShow += 1
      }
    },

    //获取图形验证码
    async imgCodeHandle() {
      var params = {
        w: 105,
        h: 43,
        t: this.$util.random(1000, 9999)
      }
      var res = await this.$api.get('/app/captcha/sendSms', params, {
        responseType: 'arraybuffer'
      })
      var data =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
      this.imgCode = data
    },
    // 修改图片
    fileClick() {
      document.getElementById('upload_file').click()
    },
    async fileChange() {
      var oFile = document.getElementById('upload_file').files[0]
      if (oFile.size > 10000000) {
        this.$message.error('上传的图片大小不能超过10M')
        return false
      }
      this.oFile = oFile
      var form = new FormData()
      form.append('type', 'avatar')
      form.append('filedata', oFile)
      let response = await fetch(`${baseHttp}/api/common/uploadImage`, {
        method: 'POST',
        body: form
      })
      let res = await response.json()
      if (res.code === 0) {
        let imgId = res.data.id
        if (!userInfo) {
          userInfo = {}
        }
        userInfo.avatar = res.data.urls[0].url
        setItem('user', userInfo)
        this.changePersonInfoApiM({
          avatarId: imgId
        })
      }
    },
    // 修改昵称，性别
    saveHandle() {
      if (!this.userInfo.sex) {
        this.$message.error('请选择性别')
        return
      }
      if (!this.userInfo.nickName) {
        this.$message.error('请填写昵称')
        return
      }
      this.changePersonInfoApiM({
        nick_name: this.userInfo.nickName,
        sex: this.userInfo.sex
      })
    },
    // 修改个人信息
    changePersonInfoApiM(val) {
      changePersonInfoApi(val).then(res => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: `修改成功!`
          })
          userInfo.nickName = this.userInfo.nickName
          setItem('user', userInfo)
          // this.$bus.$emit('userChange')
          this.$store.dispatch('setUserModal')
          this.getInit(1)
        }
      })
    },
    // 列表操作数据
    operateHandle(type, id, index) {
      // type refresh 恢复，stop停止，delete删除
      let tip = type === 'refresh' ? '恢复' : type === 'stop' ? '停用' : '删除'
      this.$confirm(`确定要${tip}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editChildM(type, id, tip, index)
      })
    },
    // 子账户操作
    async editChildM(type, id, tip, index) {
      // type = delete 删除，stop 停止，refresh 恢复
      let state =
        type === 'refresh' ? 'ALIVE' : type === 'stop' ? 'FORBID' : 'delete'
      if (state === 'delete') {
        await deleteOrgChildApi({ id }).then(res => {
          if (res.code === 0) {
            // 删除成功
            this.comList.splice(index, 1)
            this.allTotal = this.allTotal - 1
          }
        })
      } else {
        await editOrgChildApi({ id, state }).then(res => {
          if (res.code === 0) {
            this.comList[index].state = state
          }
        })
      }
      this.$message({
        type: 'success',
        message: `${tip}成功!`
      })
    },

    // 部门列表
    getdepList() {
      departmentList({}).then(res => {
        console.log(res, 'depa')
        if (res.code === 0) {
          this.departList = res.data.list
        }
      })
    },

    // 添加 子账户校对
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.addOrgM()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 添加子账户
    addOrgM() {
      addOrgApi(this.form).then(res => {
        if (res.code === 0) {
          this.dialogFormVisible = false
          this.where.page = 1
          this.loading = false
          this.getList(1)
          this.resetM()
        }
      })
    },
    // 发送验证码
    sendCodeHandle() {
      sendCodeApi({
        mobile: this.form.mobile
      }).then(res => {
        if (res.code === 0) {
          this.isCountDown = true
          this.codeTip = '60s重发'
          var currentTime = 60
          var interval = setInterval(() => {
            currentTime--
            this.codeTip = currentTime + 's重发'
            if (currentTime <= 0) {
              clearInterval(interval)
              this.codeTip = '获取验证码'
              this.isCountDown = false
              this.imgCodeHandle()
            }
          }, 1000)

          if (res.data.isUser === false) {
            this.isUser = false
          }
        } else {
          // this.$message.error(res.msg)
          this.imgCodeHandle()
          this.isUser = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
