<template>
  <div class="add-child">
    <el-dialog
      title="更换手机"
      :before-close="resetM"
      :visible.sync="ischangeMobile"
      width="540px"
    >
      <div class="form-box">
        <el-form
          ref="changeMobileForm"
          :model="changeMobileForm"
          :rules="changeMobileRules"
        >
          <el-form-item label="" prop="mobile">
            <div class="item">
              <el-input
                v-model="changeMobileForm.mobile"
                :disabled="isDisabled"
                placeholder="新手机号"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" prop="codeImg">
            <div class="item code-box">
              <el-input
                v-model="changeMobileForm.codeImg"
                placeholder="图形验证码"
              ></el-input>
              <p class="codeImg" @click="imgCodeHandle">
                <img :src="imgCode" alt="" />
              </p>
            </div>
          </el-form-item>
          <el-form-item label="" prop="code">
            <div class="item">
              <el-input
                v-model="changeMobileForm.code"
                placeholder="短信验证码"
              ></el-input>
              <span
                class="code cursor"
                :class="isCountDown ? 'no-click-gray' : ''"
                @click="sendMoblieCodeHandle"
                >{{ codeTip }}</span
              >
            </div>
          </el-form-item>

          <el-form-item>
            <div
              v-if="isDisabled"
              class="btn fcenter"
              @click="submitFormChangeMobile('next')"
            >
              下一步
            </div>
            <div
              class="btn fcenter"
              v-else
              @click="submitFormChangeMobile('sure')"
            >
              确认修改
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCodeApi,
  checkMobileApi,
  checkEditMobileApi,
  setMobileApi
} from '@/api/user'
export default {
  props: {
    mobile: [String, Number],
    isShow: [Number]
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else {
        if (!reg_tel.test(value)) {
          callback(new Error('请输入正确手机号码'))
        }
        callback()
      }
    }
    return {
      codeTip: '获取验证码',
      isCountDown: false,
      imgCode: '',
      ischangeMobile: false,
      dialogFormVisible: false,
      isDisabled: false,
      // 更换手机的
      changeMobileForm: {
        mobile: '',
        code: '',
        codeImg: ''
      },
      changeMobileRules: {
        mobile: [
          {
            required: true,
            validator: validateMobile,
            message: '请填写正确手机号码',
            trigger: 'change'
          }
        ],
        code: [{ required: true, message: '请填写验证码', trigger: 'change' }],
        codeImg: [
          { required: true, message: '请填写图形码', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    //获取图形验证码
    async imgCodeHandle() {
      var params = {
        w: 105,
        h: 43,
        t: this.$util.random(1000, 9999)
      }
      var res = await this.$api.get('/app/captcha/sendSms', params, {
        responseType: 'arraybuffer'
      })
      var data =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
      this.imgCode = data
    },
    resetM() {
      this.$refs['changeMobileForm'].resetFields()
      this.ischangeMobile = false
    },
    // 获取验证码
    sendMoblieCodeHandle() {
      if (!this.changeMobileForm.codeImg) {
        this.$message.error('请填写图形验证码')
        return
      }
      if (this.isDisabled) {
        getCodeApi({
          t: 'checkMobile',
          smsCaptchaCode: this.changeMobileForm.codeImg
        }).then(res => {
          if (res.code === 0) {
            this.isCountDown = true
            this.codeTip = '60s重发'
            var currentTime = 60
            var interval = setInterval(() => {
              currentTime--
              this.codeTip = currentTime + 's重发'
              if (currentTime <= 0) {
                clearInterval(interval)
                this.codeTip = '获取验证码'
                this.isCountDown = false
                this.imgCodeHandle()
              }
            }, 1000)
          } else {
            this.imgCodeHandle()
            // this.$message.error(res.msg)
          }
        })
      } else {
        this.checkEditMobileApiM()
      }
    },
    // 更换手机处理
    submitFormChangeMobile(type) {
      this.$refs.changeMobileForm.validate(valid => {
        if (valid) {
          // 下一步
          if (type === 'next') {
            checkMobileApi({
              code: this.changeMobileForm.code
            }).then(res => {
              // 重新输入新手机号，图形验证码，短信
              if (res.code === 0) {
                this.isDisabled = false
                this.imgCodeHandle()
                this.changeMobileForm = {}
              }
            })
          } else {
            // 确认修改
            this.setMobileApiM()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证新手机的
    checkEditMobileApiM() {
      checkEditMobileApi({
        mobile: this.changeMobileForm.mobile,
        t: 'editMobile',
        smsCaptchaCode: this.changeMobileForm.codeImg
      }).then(res => {
        if (res.code === 0) {
        }
      })
    },
    // 设置新手机
    setMobileApiM() {
      setMobileApi({
        mobile: this.changeMobileForm.mobile,
        code: this.changeMobileForm.code
      }).then(res => {
        if (res.code === 0) {
          // 修改成功
          this.ischangeMobile = false
          this.$emit('updatePage')
        }
      })
    }
  },
  watch: {
    isShow(val) {
      this.ischangeMobile = true
      this.changeMobileForm.mobile = this.mobile
      this.isDisabled = true
      this.imgCodeHandle()
    }
  }
}
</script>

<style scoped lang="scss">
.el-input.is-disabled .el-input__inner {
  background: none;
}
</style>
